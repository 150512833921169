import { classNames, getBackgroundColorClass, getBackgroundImageClass, getTagClass, getTags, ItemTypes } from '@utils';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Quote from '../../quote/Quote.jsx';
import GridAreaItemHighlighted from './grid-area-item-highlighted';
import GridAreaItemNormal from './grid-area-item-normal';
import styles from './grid-area-item.module.scss';

const GridAreaItem = ({ item, itemsCount, ...props }) => {
	if (item.type === ItemTypes.QUOTE) {
		const quote = require(`@static/cms/quotes/${item.quote}`);
		return <Quote {...props} {...quote} className={styles.quote} />;
	}

	const { highlight, capabilities, industries, ...restItem } = item;
	const backgroundImageClass = useMemo(() => getBackgroundImageClass(capabilities), [capabilities]);
	const backgroundColorClass = useMemo(() => getBackgroundColorClass(industries), [industries]);
	const tagClass = useMemo(() => getTagClass(industries), [industries]);
	const tags = useMemo(() => getTags(capabilities, industries), [capabilities, industries]);

	return highlight ? (
		<GridAreaItemHighlighted
			{...props}
			item={{ ...restItem, tags }}
			itemsCount={itemsCount}
			classes={classNames(styles[`${backgroundImageClass}Highlighted`], styles[backgroundColorClass])}
			tagClassName={styles[tagClass]}
		/>
	) : (
		<GridAreaItemNormal
			{...props}
			item={{ ...restItem, tags }}
			classes={classNames(styles[backgroundImageClass], styles[backgroundColorClass])}
			tagClassName={styles[tagClass]}
		/>
	);
};

GridAreaItem.propTypes = {
	item: PropTypes.object.isRequired,
	itemsCount: PropTypes.number.isRequired
};

export default GridAreaItem;
