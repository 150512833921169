import Button from '@components/button';
import Tags from '@components/tags';
import { classNames } from '@utils';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactMarkdown } from '@components';
import styles from './grid-area-item-highlighted-mobile.module.scss';

const GridAreaItemHighlightedMobile = ({ item, tagClassName, isRightColumn }) => {
	const { title, description, case: slug, tags, highlightImage } = item;

	return (
		<div className={classNames(styles.container, isRightColumn && styles.isRightColumn)}>
			{tags && <Tags className={styles.tags} words={tags} tagClassName={tagClassName} />}
			<h2 className={styles.text}>
				<ReactMarkdown className={styles.title} children={title} />
				<ReactMarkdown className={styles.description} children={description} />
			</h2>
			{highlightImage && <img className={styles.image + ' lazyload'} data-src={highlightImage} />}
			<div className={styles.buttonContainer}>
				<Button
					className={styles.button}
					link={`/${slug}`}
					setDefaultIconColor
					iconAfter="btn-arrow"
					block
					rounded
					shadow
					primary
					filled
					new-tab
				/>
			</div>
		</div>
	);
};

GridAreaItemHighlightedMobile.propTypes = {
	item: PropTypes.shape({
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		case: PropTypes.string.isRequired,
		tags: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.string,
				value: PropTypes.string
			})
		),
		image: PropTypes.string
	}).isRequired,
	tagClassName: PropTypes.string,
	isRightColumn: PropTypes.bool
};

GridAreaItemHighlightedMobile.defaultProps = {
	tagClassName: null,
	isRightColumn: false
};

export default GridAreaItemHighlightedMobile;
