import React from 'react';

import { classNames } from '@utils';
import Picture from '@components/picture';
import styles from './clutch-widget.module.scss';

const ClutchWidget = ({ className }) => (
	<a
		className={classNames(className, styles.widget)}
		href="https://clutch.co/profile/uruit?utm_source=widget&amp;utm_medium=widget_1&amp;utm_campaign=widget&amp;utm_content=logo"
		target="_blank"
	>
		<Picture sources={'/cms/assets/clutch-logo.svg'} />
		<div className={styles.rightContainer}>
			<Picture sources={'/cms/assets/clutch-stars.svg'} imgClassName={styles.stars} />
			<span className={styles.reviews}>+20 REVIEWS</span>
		</div>
	</a>
);

export default ClutchWidget;
