import Button from '@components/button';
import Link from '@components/link';
import Tags from '@components/tags';
import { classNames } from '@utils';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactMarkdown } from '@components';
import Picture from '@components/picture';
import styles from './grid-area-item-normal.module.scss';

const GridAreaItemNormal = ({ item, classes, tagClassName, ...props }) => {
	const { title, description, case: slug, tags, image } = item;
	return (
		<div {...props} className={styles.container}>
			<Link className={styles.clickArea} to={`/${slug}/`}></Link>
			<div className={classNames(styles.item, classes)}>
				{tags && <Tags className={styles.tags} words={tags} tagClassName={tagClassName} />}
				<div className={styles.content}>
					<h2 className={styles.text}>
						<ReactMarkdown className={styles.title} children={title} />
						<ReactMarkdown className={styles.description} children={description} />
					</h2>
					{image && <Picture className={styles.image} sources={image} />}
				</div>
				<div className={styles.buttonContainer}>
					<Button
						className={styles.button}
						link={`/${slug}`}
						setDefaultIconColor
						iconAfter="btn-arrow"
						block
						rounded
						shadow
						primary
						filled
						new-tab
					/>
				</div>
			</div>
		</div>
	);
};

GridAreaItemNormal.propTypes = {
	item: PropTypes.shape({
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		case: PropTypes.string.isRequired,
		tags: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.string,
				value: PropTypes.string
			})
		),
		image: PropTypes.string
	}).isRequired,
	classes: PropTypes.string,
	tagClassName: PropTypes.string
};

GridAreaItemNormal.defaultProps = {
	classes: null,
	tagClassName: null
};

export default GridAreaItemNormal;
