import PropTypes from 'prop-types';
import React from 'react';
import NoResults from './no-results';
import { classNames } from '@utils';
import GridAreaItem from './grid-area-item';
import AnimatedComponent, { TranslateDirection } from '@components/animated-component';
import styles from './grid-area.module.scss';

const GridArea = ({ items, animate }) => {
	return !!items.length ? (
		<AnimatedComponent animate move={TranslateDirection.Y}>
			<div className={classNames(styles.grid, animate && styles.animate)}>
				{items.map((item, i) => (
					<GridAreaItem key={i} item={item} itemsCount={items.length} />
				))}
			</div>
		</AnimatedComponent>
	) : (
		<NoResults />
	);
};

GridArea.propTypes = {
	items: PropTypes.array.isRequired
};

export default GridArea;
