import React from 'react';
import PropTypes from 'prop-types';
import styles from './quote.module.scss';
import { classNames } from '@utils';

const Quote = ({ quote, author, className, textClassName, authorClassName, ...props }) => (
	<div {...props} className={classNames(className, styles.wrapper)}>
		<div className={classNames(textClassName, styles.quote)}>{quote}</div>
		<div className={classNames(styles.author, authorClassName)}>{author}</div>
	</div>
);

Quote.propTypes = {
	quote: PropTypes.string,
	author: PropTypes.string,
	className: PropTypes.string,
	textClassName: PropTypes.string,
	authorClassName: PropTypes.string
};

Quote.defaultProps = {
	className: null,
	textClassName: null,
	authorClassName: null
};

export default Quote;
