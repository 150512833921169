import { classNames } from '@utils';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import GridAreaItemHighlightedMobile from './grid-area-item-highlighted-mobile';
import GridAreaItemHighlightedWeb from './grid-area-item-highlighted-web';
import styles from './grid-area-item-highlighted.module.scss';

const GridAreaItemHighlighted = ({ item, itemsCount, classes, tagClassName, ...props }) => {
	const { mobile } = item;

	const myRef = useRef();
	const [isRightColumn, setIsRightColumn] = useState(false);

	useEffect(() => {
		function checkItemColumn() {
			const leftPosition = myRef.current.getBoundingClientRect().left;
			const { innerWidth: width } = window;
			setIsRightColumn(leftPosition >= width / 2);
		}
		window.addEventListener('resize', checkItemColumn);
		checkItemColumn();
		return () => window.removeEventListener('resize', checkItemColumn);
	}, [itemsCount]);

	return (
		<div {...props} className={classNames(styles.container, isRightColumn && styles.isRightColumn)} ref={myRef}>
			<div className={classNames(styles.item, classes)}>
				{mobile ? (
					<GridAreaItemHighlightedMobile
						item={item}
						tagClassName={tagClassName}
						isRightColumn={isRightColumn}
					/>
				) : (
					<GridAreaItemHighlightedWeb item={item} tagClassName={tagClassName} isRightColumn={isRightColumn} />
				)}
			</div>
		</div>
	);
};

GridAreaItemHighlighted.propTypes = {
	item: PropTypes.shape({
		mobile: PropTypes.bool
	}).isRequired,
	itemsCount: PropTypes.number.isRequired,
	classes: PropTypes.string,
	tagClassName: PropTypes.string
};

GridAreaItemHighlighted.defaultProps = {
	classes: null,
	tagClassName: null
};

export default GridAreaItemHighlighted;
