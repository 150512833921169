import React from 'react';
import PropTypes from 'prop-types';
import Picture from '@components/picture';
import Button from '@components/button';
import styles from './no-results.module.scss';

const NoResults = ({ text, subText, image, button }) => (
	<div className={styles.container}>
		<div className={styles.textColumn}>
			<h2 className={styles.subtitle}>{text}</h2>
			<h2 className={styles.subtitle}>{subText}</h2>
			<Button link={button.to} title={button.title}>
				{button.title}
			</Button>
		</div>
		<div className={styles.column}>
			<Picture className={styles.media} imgClassName={styles.image} sources={image} />
		</div>
	</div>
);

NoResults.propTypes = {
	text: PropTypes.string.isRequired,
	subText: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
	button: PropTypes.object.isRequired
};

NoResults.defaultProps = {
	text: "Sorry, we can't find the product you're looking for.",
	subText: "Let's build it together?",
	image: '/cms/assets/error-no-results.svg',
	button: {
		title: 'Contact us',
		to: '/contact'
	}
};

export default NoResults;
